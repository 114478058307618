<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">供应商审核</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item>
						<el-input :maxlength="20" v-model="seachForm.name" clearable placeholder="请输入供应商名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.state" clearable placeholder="请选择状态">
							<el-option v-for="item in roleIdsoption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="supplierName" label="供应商名称" width="200">
				</el-table-column>
				<el-table-column prop="jumpUrl" label="跳转链接/指定地址" width="200">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<p v-if="scope.row.jumpUrl">{{scope.row.jumpUrl}}</p>
							<p v-else>{{scope.row.indexUrl}}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="contacts" label="联系人" width="150">
				</el-table-column>
				<el-table-column prop="phone" label="手机号" width="200">
				</el-table-column>
				<el-table-column prop="createTime" label="提交时间" width="170">
				</el-table-column>
				<el-table-column prop="checkStatus" label="状态" width="150">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<div style="display: flex;">
								<p v-if="scope.row.checkStatus==0">待审核</p>
								<p v-else-if="scope.row.checkStatus==1">审核通过</p>
								<p v-else>审核拒绝</p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="checkTime" label="审核时间" width="170">
				</el-table-column>
				<el-table-column prop="checkBy" label="审核人">
				</el-table-column>				
				<el-table-column fixed="right" label="操作" width="150">
					<template style="display: flex;" slot-scope="scope">
						<div v-if="data[scope.$index].checkStatus == 0">
							<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
								修改
							</el-button>
							<el-button @click.native.prevent="auditList(scope.$index, data)" type="text" size="small">
								审核
							</el-button>
						</div>
						<div v-else>
							<el-button disabled type="text" size="small">
								修改
							</el-button>
							<el-button disabled type="text" size="small">
								审核
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" title="修改供应商">
			<el-form class="form_box" :model="sizeForm" label-position='left'  ref="sizeForm" label-width="120px" size="small">
				<el-form-item prop="supplierName" label="供应商名称：">
					<el-input type="text" v-model="sizeForm.supplierName"></el-input>
				</el-form-item>
				<el-form-item label="跳转类型：">
					<el-radio-group v-model="resource" @change="radioChange">
						<el-radio label="1">跳转小程序</el-radio>
						<el-radio label="2">跳转外链</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接地址：" v-if="resource == '2'">
					<el-input type="text" v-model="sizeForm.jumpUrl"></el-input>
				</el-form-item>
				<el-form-item label="APPID：" v-if="resource == '1'">
					<el-input type="text" v-model="sizeForm.appId"></el-input>
				</el-form-item>
				<el-form-item label="指定地址：" v-if="resource == '1'">
					<el-input type="text" v-model="sizeForm.indexUrl"></el-input>
				</el-form-item>
				<el-form-item label="联系人：" prop="contacts">
					<el-input type="text" v-model="sizeForm.contacts"></el-input>
				</el-form-item>
				<el-form-item label="手机号：" prop="phone">
					<el-input type="text" v-model="sizeForm.phone" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)"></el-input>
				</el-form-item>
				<el-form-item label="LOGO：" prop="imgUrl">
					<el-upload class="avatar-uploader" action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="sizeForm.imgUrl" :src="sizeForm.imgUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="排序值：" prop='sort'>
					<el-input type="number" v-model="sizeForm.sort" @blur="sortBlur"></el-input>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button type="primary" @click.native.prevent="onUpdata('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 审核弹窗 -->
		<el-dialog :visible.sync="auditState" width="700px" :before-close="handleClose" title="供应商审核">
			<el-form class="form_box" :model="sizeForm" label-position='left'  ref="sizeForm" label-width="120px" size="small">
				<el-form-item label="供应商名称：">
					{{sizeForm.supplierName}}
				</el-form-item>
				<el-form-item label="跳转类型：">
					<el-radio-group v-model="resource" @change="radioChange" disabled>
						<el-radio label="1">跳转小程序</el-radio>
						<el-radio label="2">跳转外链</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接地址：" v-if="resource == '2'">
					{{sizeForm.jumpUrl}}
				</el-form-item>
				<el-form-item label="APPID：" v-if="resource == '1'">
					{{sizeForm.appId}}
				</el-form-item>
				<el-form-item label="指定地址：" v-if="resource == '1'">
					{{sizeForm.indexUrl}}
				</el-form-item>
				<el-form-item label="联系人：">
					{{sizeForm.contacts}}
				</el-form-item>
				<el-form-item label="手机号：">
					{{sizeForm.phone}}
				</el-form-item>
				<el-form-item label="排序值：">
					{{sizeForm.sort}}
				</el-form-item>
				<el-form-item label="审核状态：">
					<el-radio-group v-model="roleIdoptionValue">
						<el-radio label="allow">审核通过</el-radio>
						<el-radio label="refuse">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button type="primary" @click.native.prevent="auditSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		supplierAuditList,supplierUpdate,supplierAudit
	} from "@/api/platform/index";
	export default {
		name: "audit",
		data() {
			return {
				seachForm:{
					name: '',
					state: '',
				},
				resource:'1',
				image_url: imageUrl,
				roleIdsoption: [{label: '待审核',value: 0},{label: '审核通过',value: 1},{label: '审核拒绝',value: 2}],
				roleIdoptionValue:'allow',
				headers: {
					Authorization: this.$store.state.user.Token
				},
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					supplierName: '',
					contacts: '',
					phone: '',
					appId: '',
					jumpUrl: '',
					indexUrl:'',
					sort: 1,
					imgUrl:''
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				id: 0,
				auditState:false,
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//审核状态
			auditSubmit(){
				supplierAudit(this.roleIdoptionValue + '/' + this.id).then(res => {
					if (res.code == 200) {
						this.$message.success('提交成功');
						this.sizeForm = {
							supplierName: '',
							contacts: '',
							phone: '',
							appId: '',
							jumpUrl: '',
							indexUrl:'',
							sort: 1,
							imgUrl:''
						}
						this.auditState = false
						this.page = 1
						this.list();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			// 排序值验证
			sortBlur(){
				let that = this;
				if(Number(that.sizeForm.sort) >= 0){
					that.sizeForm.sort = that.sizeForm.sort;
				}else{
					that.$message.error('排序值不能为负数');
				}
			},
			auditList(i,tab){
				console.log(tab[i])
				this.sizeForm = {
					supplierName: tab[i].supplierName,
					contacts: tab[i].contacts,
					phone: tab[i].phone,
					appId: tab[i].appId,
					jumpUrl: tab[i].jumpUrl,
					indexUrl: tab[i].indexUrl,
					sort: tab[i].sort,
					imgUrl:tab[i].imgUrl
				}
				this.id = tab[i].id
				this.auditState = true
				if(this.sizeForm.appId){
					this.resource = '1'
				}else{
					this.resource = '2'
				}
				if(!this.sizeForm.sort){
					this.sizeForm.sort = 1
				}
			},
			//radio切换
			radioChange(e){
			
			},
			//搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},
			//获取列表
			list() {
				supplierAuditList({
					page: this.page,
					size: this.pageSize,
					checkStatus: this.seachForm.state,
					supplierName: this.seachForm.name
				}).then(res => {
					console.log(res)
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
			//分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.list();
			},
			//编辑提交
			onUpdata(formName){
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.supplierName == ''){
								this.$message.warning('请输入供应商名称');
							}else if(this.$refs[formName].model.contacts == ''){
								this.$message.warning('请输入联系人');
							}else if(this.$refs[formName].model.phone == ''){
								this.$message.warning('请输入手机号');
							}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.$refs[formName].model.phone))){
								this.$message.warning('手机号不合法');
							}else if(this.resource == '1' && this.$refs[formName].model.appId == ''){
								this.$message.warning('请输入APPID');
							}else if(this.resource == '1' && this.$refs[formName].model.indexUrl == ''){
								this.$message.warning('请输入指定地址');
							}else if(this.resource == '2' && this.$refs[formName].model.jumpUrl == ''){
								this.$message.warning('请输入跳转地址');
							}else if(this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('LOGO图片不能为空');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('排序值不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('排序值不能为负数');
							}else{
								if(this.resource == '2'){
									this.$refs[formName].model.appId = '';
									this.$refs[formName].model.indexUrl = '';
								}else if(this.resource == '1'){
									this.$refs[formName].model.jumpUrl = ''
								}
								supplierUpdate({
									supplierName: this.$refs[formName].model.supplierName,
									contacts: this.$refs[formName].model.contacts,
									phone: this.$refs[formName].model.phone,
									appId:this.$refs[formName].model.appId,
									jumpUrl: this.$refs[formName].model.jumpUrl,
									indexUrl: this.$refs[formName].model.indexUrl,
									sort: this.$refs[formName].model.sort,
									imgUrl: this.$refs[formName].model.imgUrl,
									id: this.id
								}).then(res=>{
									if (res.code == 200) {
										this.$message.success('编辑成功');
										this.sizeForm = {
											supplierName: '',
											contacts: '',
											phone: '',
											appId: '',
											jumpUrl: '',
											indexUrl:'',
											sort: 1,
											imgUrl:''
										}
										this.addshow = false
										this.page = 1
										this.list();
									} else {
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},

			//图片上传
			handleAvatarSuccess(res, file) {
				console.log(res)
				if(res.code == 200){
					this.sizeForm.imgUrl = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},
			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					supplierName: '',
					contacts: '',
					phone: '',
					appId: '',
					jumpUrl: '',
					indexUrl:'',
					sort: 1,
					imgUrl:''
				}
				this.addshow = false
				this.auditState = false
			},
			//编辑按钮点击
			updataclick(i,tab){
				console.log(tab[i])
				this.sizeForm = {
					supplierName: tab[i].supplierName,
					contacts: tab[i].contacts,
					phone: tab[i].phone,
					appId: tab[i].appId,
					jumpUrl: tab[i].jumpUrl,
					indexUrl: tab[i].indexUrl,
					sort: tab[i].sort,
					imgUrl: tab[i].imgUrl,
				}
				this.id = tab[i].id
				if(this.sizeForm.appId){
					this.resource = '1'
				}else{
					this.resource = '2'
				}
				if(!this.sizeForm.sort){
					this.sizeForm.sort = 1
				}
				this.addshow = true
			},
		}
	}
</script>

<style scoped lang="scss">
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}
	.search{display:flex;align-items:center;}
	.search .el-form-item{margin:0 20px 0 0 !important;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
